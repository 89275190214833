import './App.css';
import { useState, useEffect } from 'react'
import Popup from './components/Popup/Popup';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Views from './views/Views';


function App() {

  const [timedPopup, setTimedPopup] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setTimedPopup(true)
    }, 300)
  }, [])

  // useEffect(() => {
  //   setTimeout(() => {
  //     setTimedPopup(false)
  //   }, 8000)
  // }, [])

  return (
    <div className="">
      <div>
        <Popup trigger={timedPopup} setTrigger={setTimedPopup}></Popup>
      </div>
      <Navbar />
        <Views />
      <Footer />
    </div>
  );
}

export default App;
