import React from 'react'
import { Link } from 'react-router-dom'
import imgLogo from '../img/Logo.png'

const Footer = () => {
  return (
    
    <div className='pt-3'>
      
      <div className='container row row-cols-1 row-cols-md-3 g-4 mb-4'>
        
        <div className='text-center text-md-start text'>
          <p className='h6'>Provito AB</p>
          <p className='p-0 m-0'><span className='fw-bold'>Tel: </span>070 - 630 13 70</p>
          <p className='p-0 m-0'><span className='fw-bold'>E-post: </span>info@provito.se</p>
          <p className='p-0 m-0'><span className='fw-bold'>Adress: </span> Södra Ringvägen 22,
          739 31  Skinnskatteberg</p>
        </div>

        <div className='text-center py-2'>
          <Link to="/" className="logo"><img className='logo' src={imgLogo} alt="Logotype"/></Link>
        </div>

        <div className='text-center text-md-end links'>
          <Link to='/' className='link'>Hem</Link>
          <Link to='/about' className='link'>Om oss</Link>
          {/* <Link to='/services' className='link'>Tjänster</Link> */}
          <Link to='/contact' className='link'>Kontakt</Link>
        </div>

      </div>

      <p className='border-top pt-1 text-center'>© 2023 Provito AB</p>
    </div>
  )
}

export default Footer