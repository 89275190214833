import React from 'react'
import imgTony from '../img/TonyGrey.jpg'


const Contact = () => {
  return (
    <div className='mt-views bg-curve-bottom py-5'>
      <div className='container mb-md-3 mb-lg-5'>
        <h4 className='text-center py-4'>Provito erbjuder IT-lösningar</h4>
        <p className='text-center py-4'>Kontakta Tony för mer information om vad Provito kan erbjuda just er!</p>

        <div className='card card1 mx-auto text-center justify-content-center align-items-center py-5 mb-5'>            
          <img className='avatar' src={imgTony} alt="Tony VD"/>
          <div className='pt-3'>
            <h6>Tony Hildingstam, VD</h6>
            <p className='m-0 pt-3'>Tel: 070 - 630 13 70</p>
            <p className='m-0'>E-post: info@provito.se</p>
            {/* <p className='m-0'>Kontor: Södra Ringvägen 22, Skinnskatteberg</p> */}
          </div>
        </div>

      </div>
    </div>
  )
}

export default Contact