import React from 'react'
import { Link } from 'react-router-dom'


const Services = () => {
  return (
    <div className='bg-second-blue text-light pt-5 pb-3'>

      <div className='text-center container'>
        <h4 className='text-center py-4'>Provito erbjuder IT-lösningar</h4>
        <div className='row g-4 py-3 mt-md-5'>
          <div className='col-md-4 col-xl-5 text-md-end pe-xl-5'>
            <h6>Systemutveckling</h6>
          </div>
          <div className='col-md-4 col-xl-2'>
            <h6>Systemförvaltning</h6>
          </div>
          <div className='col-md-4 col-xl-5 text-md-start ps-xl-5'>
            <h6>Projekt & agilt</h6>
          </div>
        </div>
        <div className='row g-4 pt-5 pb-5 mt-md-3'>
          <div className='d-none d-md-block col-md-1 col-lg-2 mb-lg-5 pb-lg-3'>
          </div>
          <div className='col-md-10 col-lg-8 mb-lg-5 pb-lg-3'>
            <p className='pb-5 pb-xl-5'>
              Provito har över 30 års erfarenhet inom IT och expertis inom retail.
            </p>
            <div className='mt-xl-5 px-md-3 px-xl-5'>
              <p className='mt-5 pt-5 pt-sm-3 pt-lg-5'>
                Vi erbjuder lösningar på allt från systemutveckling till systemförvaltning 
                och har lång erfarenhet av teknisk projektledning och konsultarbete i agila projekt, stora som små. 
              </p>
              <p>Med vår breda erfarenhet och höga kompetens låser vi oss inte till något visst 
                programmeringsspråk eller någon universal lösning som ska passa alla, 
                vi utgår från den IT-investering som just ni vill genomföra 
                när vi tar fram lösningsförslag. Kontakta oss så berättar vi mer!
              </p>
            </div>
          </div>
          <div className='d-none d-md-block col-md-1 col-lg-2 mb-lg-5 pb-lg-3'>
          </div>
        </div>  

        <div className='text-center text-md-end'>
          <Link to='/contact'>
            <button className='btn-icon text-light'>
              <div className='d-flex justify-content-center align-items-center'>
                <i className="fa-solid fa-arrow-right-long"></i>
                <h4 className='ps-2'>Kontakta oss</h4>
              </div>
            </button>
          </Link>
        </div>

      </div>

    </div>
  )
}

export default Services