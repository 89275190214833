import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import imgLogo from '../img/Logo.png'

const Navbar = () => {
  return (   
    <nav className="navbar navbar-expand-md fixed-top navbar-light bg-light">
      <div className="container-fluid container-lg">
        <Link to="/" className="navbar-brand"><img className='logo' src={imgLogo} alt="Logotype"/></Link>
        {/* Burger icon */}
          <button
            class="navbar-toggler"
            type="button"
            data-mdb-toggle="collapse"
            data-mdb-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i class="fas fa-bars"></i>
          </button>
          {/* Links */}
          <div class="collapse navbar-collapse justify-content-md-end" id="navbarNav">
          <div className="navbar-nav">
            <NavLink to="/" className="nav-link pe-3">Hem</NavLink>
            <NavLink to="/about" className="nav-link pe-3">Om oss</NavLink>
            <NavLink to="/contact" className="nav-link pe-3">Kontakt</NavLink>
          </div>
        </div>
      </div>
    </nav>  

  )
}

export default Navbar