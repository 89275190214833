import React from 'react'
import imgLogoGrey from '../img/ProvitoGrey.png'
import { Link } from 'react-router-dom'

const Hero = () => {
  return (
    <div className='mt-3 container'>

      <div className='row text-center align-items-center'>
        <div className='col-12 col-md-6 d-none d-sm-inline'>
          <img className='logo-grey' src={imgLogoGrey} alt="Logotype"/>
        </div>
        <div className='col-12 col-md-6'>
          <h1 className=''>Konsulter inom IT</h1>
        </div>
      </div>

      <div className='row text-center text-md-end pb-3'>
        <div className='col-12 col-sm-6'>
        </div>
        <div className='col-12 col-md-6 text-md-center pt-md-3 pt-5'>
          <Link to='/contact'>
            <button className='btn-icon'>
              <div className='d-flex justify-content-center align-items-center'>
                <i className="fa-solid fa-arrow-right-long"></i>
                <h4 className='ps-2'>Kontakta oss</h4>
              </div>
            </button>
          </Link>
        </div>
      </div>

    </div>
  )
}

export default Hero