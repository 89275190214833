import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './Home'
import About from './About'
import Contact from './Contact'


const Views = () => {
  return (
      <Routes>
        <Route path='/' element={ <Home />} />
        <Route path='/about' element={ <About />} />
        <Route path='/contact' element={ <Contact />} />

        <Route path='*' element={
              <div className='mt-views text-center'>
                <h5 className='p-5'>Sorry, could not find that page...</h5>
              </div>
          } />
          
      </Routes>
  )
}

export default Views