import React from 'react'
import './Popup.css'
import '../../App.css'

function Popup(props) {
  return (props.trigger) ? (
    <div className='popup'>
      <div className="popup-inner">
        <button className="close-btn" onClick={() => props.setTrigger(false)}><i className="fa-solid fa-x"></i></button>

        <div className="info pt-2">
          <div className='white-card pt-1'>
            {/* <h2 className='ta-c fw'> Cookies</h2> */}
            <p className='text-center'> Cookies
            {/* <p className='info-text ta-c'> - använder vi bara för webplatsens funktionalitet. Vi sparar inga personuppgifter.</p> */}
            <small className='info-text'> - använder vi bara för webplatsens funktionalitet. Vi sparar inga personuppgifter.</small>
            </p>
          </div>
        </div>

        { props.children }
      </div>
    </div>
  ) : "";
}

export default Popup;