import React from 'react'
import { Link } from 'react-router-dom'
import Services from '../components/Services'
import Staff from '../components/Staff'


const About = () => {
  return (
    <div className='mt-views bg-curve-bottom pb-5'>
      <Services />
      <Staff />

      <div className='pb-3 text-center text-md-end container'>
        <Link to='/contact'>
          <button className='btn-icon'>
            <div className='d-flex justify-content-center align-items-center'>
              <i className="fa-solid fa-arrow-right-long"></i>
              <h4 className='ps-2'>Kontakta oss</h4>
            </div>
          </button>
        </Link>
      </div>

    </div>
  )
}

export default About