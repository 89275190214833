import React from 'react'
import { Link } from 'react-router-dom'
import Hero from '../components/Hero'


const Home = () => {
  return (
    <div className='mt-views'>
      
    {/* Hero section */}
      <div className='pt-5'>
        <Hero />
      </div>

    {/* Services section */}
      <div className='mt-5 pt-5 pb-5 bg-blue-curve'>
        <div className='container pt-5 text-center mt-xl-5 mb-xl-5'>

          <div className='row row-cols-1 row-cols-lg-3 py-5 mt-xl-5 text-light'>
            <div className='service py-3 pt-md-4 pt-xl-5 mt-lg-5'>
              <i className="fa-solid fa-circle circle"></i>
              <Link to='/about'>
                <h4 className='card2'>Systemutveckling</h4>
              </Link>
            </div>
            <div className='service py-3 pt-md-4 pt-xl-5 mt-lg-5'>
              <i className="fa-solid fa-circle circle"></i>
              <Link to='/about'>
                <h4 className='card2'>Systemförvaltning</h4>
              </Link>
            </div>
            <div className='service py-3 pt-md-4 pt-xl-5 mt-lg-5'>
              <i className="fa-solid fa-circle circle"></i>
              <Link to='/about'>
                <h4 className='card2'>Projekt & agilt</h4>
              </Link>
            </div>
          </div>

        </div>
      </div>

    {/* About section */}
      <div className='bg-curve-bottom mt-5 pt-3 pt-lg-5 pb-3'>
        <div className='container text-center'>
          <h4>30+ års erfarenhet inom professionell IT!</h4>

          <div className='text-center pt-5 pb-3 pb-md-5 mt-xl-5 mb-3 mb-lg-5'>
            <Link to='/contact'>
              <button className='btn-icon'>
                <div className='d-flex justify-content-center align-items-center'>
                  <i className="fa-solid fa-arrow-right-long"></i>
                  <h4 className='ps-2'>Kontakta oss</h4>
                </div>
              </button>
            </Link>
          </div>

        </div>
      </div>


    </div>
  )
}

export default Home