import React from 'react'
import imgLogo from '../img/Logo.png'
import imgTony from '../img/TonyGrey.jpg'
import imgVivi from '../img/ViviGrey.jpg'
import imgSteppo from '../img/SteppoGrey.jpg'
import imgJenny from '../img/JennyGrey.jpg'


const Staff = () => {
  return (
    <div className='mt-3'>
      
      <div className='pt-5 pb-3'>

        <div className='text-center'>
          <img className='logo' src={imgLogo} alt="Logotype"/>
          <h4 className='text-center py-4'>Konsulter inom IT</h4>
        </div>

        <div className='container pt-3 pb-5'>
          <p className='text-center pb-3'>
            Provito - ett sammansvetsat gäng som är experter på systemförvaltning 
            inom retailbranschen. Över 30 års yrkeserfarenhet inom IT.
          </p>

          {/* Grid with avatars/staff */}
          <div className="row g-2 justify-content-center">
            <div className='card col-md-6 col-lg-3 card1 p-sm-4 text-center text-sm-start flex-sm-row flex-md-column align-items-center align-items-md-start'>            
              <img className='avatar me-sm-4' src={imgTony} alt="Tony VD"/>
              <div className='pt-1'>
                <h6>"The Boss"</h6>
                <p className='m-0 pt-3'>Driftig VD, multitaskande kraft. Provitos kundkontakt.</p>
                <p className='m-0'>Briljerar inom utveckling och support.</p>
                <p className='m-0'>Motto: Livet leker i Skinnskatteberg!</p>
              </div>
            </div>
            <div className='card col-md-6 col-lg-3 card1 p-sm-4 text-center text-sm-start flex-sm-row flex-md-column align-items-center align-items-md-start'>            
              <img className='avatar me-4' src={imgVivi} alt="Vivi supportansvarig"/>
              <div className='pt-1'>
                <h6>"The Rock"</h6>
                <p className='m-0 pt-3'>Allvetande viceVD, problemlösare av rang.</p>
                <p className='m-0'>Supportklippa och förvaltningsguru.</p>
                <p className='m-0'>Motto: Norrlands guld är guld!</p>
              </div>
            </div>
            <div className='card col-md-6 col-lg-3 card1 p-sm-4 text-center text-sm-start flex-sm-row flex-md-column align-items-center align-items-md-start'>            
              <img className='avatar me-4' src={imgSteppo} alt="Steppo utvecklare"/>
              <div className='pt-1'>
                <h6>"The Artist"</h6>
                <p className='m-0 pt-3'>Oumbärlig utvecklare, får jobbet gjort.</p>
                <p className='m-0'>Kodexpert och supportmästare.</p>
                <p className='m-0'>Motto: Rock n roll!</p>
              </div>  
            </div>
            <div className='card col-md-6 col-lg-3 card1 p-sm-4 text-center text-sm-start flex-sm-row flex-md-column align-items-center align-items-md-start'>            
              <img className='avatar me-4' src={imgJenny} alt="Jenny"/>
              <div className='pt-1'>
                <h6>"The Newbie"</h6>
                <p className='m-0 pt-3'>Nykomlingen som kan.</p>
                <p className='m-0'>Vetgirig alltiallo.</p>
                <p className='m-0'>Motto: Lets do it!</p>
              </div>
            </div>
          </div>
          {/* End of grid with avatars/staff */}

        </div>
        {/* End of container */}

      </div>

    </div>
  )
}

export default Staff